import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'react-emotion'
import Img from 'gatsby-image'
import { Card } from 'antd'

import Layout from '../components/layout'

const { Meta } = Card

const AboutContainer = styled.div`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    width: 100%;
  }
`

const AboutPage = ({ data, location }) => (
  <Layout location={location}>
    <AboutContainer>
      <h1>About Us</h1>
      <p>
        Since its inception in 2014, CoD Stats has provided Call of Duty esports statistics and
        analysis to fans, casters, and teams. Our goal has always been to bring the most in-depth
        stats and analytical work to create the one-stop-shop for every competitive Call of Duty fan
        while also advancing the way information can be used strategically by players and teams.
      </p>
      <p>
        With the revamp of our website, <a href="https://codstats.gg">codstats.gg</a>, we look to
        focus on creating even more advanced stats to provide more context, better determine
        individual player impact and strategic analysis. We will also be preparing content to be
        published on the new and improved website, focusing on player and team stats through our
        analytics, that will spark interesting discussions and debates for the Call of Duty esports
        community to enjoy.
      </p>
      <p>
        This season we look forward to pushing statistics in a new direction and new heights for
        Call of Duty and for esports as a whole.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          padding: '20px 0px',
        }}
      >
        <Card
          style={{ width: 240, margin: '20px 0px' }}
          cover={<Img fixed={data.jp.childImageSharp.fixed} />}
        >
          <Meta style={{ textAlign: 'center' }} title="JP Krez" description="@JPKrez" />
        </Card>
        <Card
          style={{ width: 240, margin: '20px 0px' }}
          cover={<Img fixed={data.doug.childImageSharp.fixed} />}
        >
          <Meta style={{ textAlign: 'center' }} title="Doug Liebe" description="@dougliebe" />
        </Card>
        <Card
          style={{ width: 240, margin: '20px 0px' }}
          cover={<Img fixed={data.stephengfriend.childImageSharp.fixed} />}
        >
          <Meta
            style={{ textAlign: 'center' }}
            title="Stephen G. Friend"
            description="@stephengfriend"
          />
        </Card>
      </div>
    </AboutContainer>
  </Layout>
)

AboutPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default AboutPage

export const query = graphql`
  query {
    jp: file(relativePath: { eq: "jp_2018.jpg" }) {
      childImageSharp {
        fixed(width: 240) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    doug: file(relativePath: { eq: "doug_2018.png" }) {
      childImageSharp {
        fixed(width: 240) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    stephengfriend: file(relativePath: { eq: "stephengfriend_2018.png" }) {
      childImageSharp {
        fixed(width: 240) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
